.header-logo {
  height: 55px;
  background-color: rgba(0,0,0,0);
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Subheading {
  font-family: 'Futura', Arial, Helvetica, sans-serif;
  color: #233e86;
}
