body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Keep Calm Med';
  src: url('../../assets/fonts/KeepCalm-Medium.eot');
  src: url('../../assets/fonts/KeepCalm-Medium.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/KeepCalm-Medium.woff2') format('woff2'),
       url('../../assets/fonts/KeepCalm-Medium.woff') format('woff'),
       url('../../assets/fonts/KeepCalm-Medium.svg#KeepCalm-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.Center {
  text-align: center;
}

.Heading {
  font-family: 'Keep Calm Med';
  color: #1b8bb8;
}

.Ronpon-gray {
  color: #f1f2f2;
}

.Ronpon-light-blue {
  color: #0aceff;
}

.Ronpon-dark-blue {
  color: #233e86;
}

.Ronpon-pin-blue {
  color: #1b8bb8;
}

.bg-Ronpon-pin-blue {
  background-color: #1b8bb8;
}

*, p, span, form, .myBody-bg-color {
  background-color: #f1f2f2;
}

.card {
  border: none;
  border-radius: 0;
  width: 100%;
}

.max-width-container {
  width: min(100% - 2rem, 900px);
}

/* https://stackoverflow.com/questions/21973298/how-to-remove-or-customize-the-pagination-button-in-bootstrap-carousel */
.carousel-indicators li {
  display: none;
}

.carousel-control-next, .carousel-control-prev {
  filter: invert(100%);
}

@media (max-width: 767px) {
  .carousel {
    margin-bottom: 7%;
  }
  .carousel-indicators {
    bottom: auto;
    top: 105%;
  }
  .carousel-indicators li {
    border-color: #666;
  }
  .carousel-indicators .active {
    background-color: #666;
  }
}

